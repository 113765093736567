// src/Components/admin/sections/LandingPageContent.js
import React, { useState } from "react";
import { motion } from "framer-motion";
import { useContent } from "../../../hooks/useContent";
import ImageUploader from "../ImageUploader";

const LandingPageContent = () => {
  const { content, setContent, saveContent, loading, error } = useContent();
  const [activeSection, setActiveSection] = useState("hero");
  const [saveStatus, setSaveStatus] = useState("");

  const handleSave = async () => {
    setSaveStatus("Saving...");
    const success = await saveContent(content);
    setSaveStatus(success ? "Saved successfully!" : "Error saving");
    setTimeout(() => setSaveStatus(""), 3000);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-bold">Landing Page Content</h1>

      <div className="bg-white rounded-lg shadow p-6">
        {/* Section selector */}
        <div className="mb-6 border-b">
          <div className="flex space-x-4">
            <button
              onClick={() => setActiveSection("hero")}
              className={`pb-2 ${
                activeSection === "hero"
                  ? "border-b-2 border-blue-500 text-blue-500"
                  : ""
              }`}
            >
              Hero Section
            </button>
            <button
              onClick={() => setActiveSection("mainContent")}
              className={`pb-2 ${
                activeSection === "mainContent"
                  ? "border-b-2 border-blue-500 text-blue-500"
                  : ""
              }`}
            >
              Main Content
            </button>
          </div>
        </div>

        {/* Hero section editor */}
        {activeSection === "hero" && (
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Title
              </label>
              <input
                type="text"
                value={content.hero.title || ""}
                onChange={(e) =>
                  setContent({
                    ...content,
                    hero: { ...content.hero, title: e.target.value },
                  })
                }
                className="mt-1 block w-full rounded-md border border-gray-300 p-2"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Subtitle
              </label>
              <input
                type="text"
                value={content.hero.subtitle || ""}
                onChange={(e) =>
                  setContent({
                    ...content,
                    hero: { ...content.hero, subtitle: e.target.value },
                  })
                }
                className="mt-1 block w-full rounded-md border border-gray-300 p-2"
              />
            </div>
          </div>
        )}

        {/* Main Content section editor */}
        {activeSection === "mainContent" && (
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Paragraph 1
              </label>
              <textarea
                value={content.mainContent?.paragraph1 || ""}
                onChange={(e) =>
                  setContent({
                    ...content,
                    mainContent: {
                      ...content.mainContent,
                      paragraph1: e.target.value,
                    },
                  })
                }
                rows={4}
                className="mt-1 block w-full rounded-md border border-gray-300 p-2"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Paragraph 2
              </label>
              <textarea
                value={content.mainContent?.paragraph2 || ""}
                onChange={(e) =>
                  setContent({
                    ...content,
                    mainContent: {
                      ...content.mainContent,
                      paragraph2: e.target.value,
                    },
                  })
                }
                rows={4}
                className="mt-1 block w-full rounded-md border border-gray-300 p-2"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Quote
              </label>
              <textarea
                value={content.mainContent?.quote || ""}
                onChange={(e) =>
                  setContent({
                    ...content,
                    mainContent: {
                      ...content.mainContent,
                      quote: e.target.value,
                    },
                  })
                }
                rows={4}
                className="mt-1 block w-full rounded-md border border-gray-300 p-2"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Main Image
              </label>
              <ImageUploader
                currentImage={content.mainContent?.imageSrc}
                onImageUpload={(imageUrl) =>
                  setContent({
                    ...content,
                    mainContent: {
                      ...content.mainContent,
                      imageSrc: imageUrl,
                    },
                  })
                }
              />
            </div>
          </div>
        )}

        <div className="flex justify-end space-x-4 mt-6">
          <button
            onClick={() => window.location.reload()}
            className="px-4 py-2 border rounded hover:bg-gray-50"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Save Changes
          </button>
          {saveStatus && (
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="ml-4 text-sm text-gray-600 self-center"
            >
              {saveStatus}
            </motion.p>
          )}
        </div>
      </div>
    </div>
  );
};

export default LandingPageContent;
