import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { ChevronRight, Facebook, Twitter, Instagram } from "lucide-react";
import { db } from "../firebase";
import {
  collection,
  addDoc,
  serverTimestamp,
  query,
  where,
  getDocs,
  limit,
} from "firebase/firestore";
import { addRegistration } from "../firebase/registrations";
import {
  trackPageView,
  trackEvent,
  trackEmailSignup,
  trackSocialShare,
} from "../utils/analytics";
import { pre } from "framer-motion/client";
import { useContent } from "../hooks/useContent";
import { Helmet } from "react-helmet-async";
import { useSeoSettings } from "../hooks/useSeoSettings";
import ResponsiveInput from "./ResponsiveInput";

const colors = {
  primaryDark: "#304b78",
  primaryLight: "#435e95",
  secondary: "#2494a2",
  accent: "#f3d678",
  backgroundDark: "#0f172a",
  backgroundLight: "#1e293b",
};

//email validation regex
const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const LandingPage = () => {
  const { content, contentLoading } = useContent();
  const { seoSettings, loading: seoLoading } = useSeoSettings();
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState({ type: "", message: "" });
  const [emailError, setEmailError] = useState("");

  // Validate email on change
  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    // Clear errors when user starts typing
    if (emailError) setEmailError("");
    if (submitStatus.message) setSubmitStatus({ type: "", message: "" });
  };

  // Validate email before submission
  const validateEmail = (email) => {
    if (!email) {
      setEmailError("Email is required");
      return false;
    }
    if (!EMAIL_REGEX.test(email)) {
      setEmailError("Please enter a valid email address");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setEmailError("");
    setSubmitStatus({ type: "", message: "" });

    if (!validateEmail(email)) {
      return;
    }

    setIsSubmitting(true);

    try {
      const normalizedEmail = email.toLowerCase().trim();

      // Simplified data structure without timestamp
      const registrationData = {
        email: normalizedEmail,
        source: "landing_page",
      };

      console.log("Registration data:", JSON.stringify(registrationData));

      // First check if email exists
      const emailsRef = collection(db, "registrations");
      const q = query(
        emailsRef,
        where("email", "==", normalizedEmail),
        limit(1)
      );

      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        setSubmitStatus({
          type: "info",
          message: "This email is already registered!",
        });
        return;
      }

      // Try to add the document
      const docRef = await addDoc(
        collection(db, "registrations"),
        registrationData
      );
      console.log("Document written with ID: ", docRef.id);

      setSubmitStatus({
        type: "success",
        message: "Thank you for registering your interest!",
      });
      setEmail("");

      setTimeout(() => {
        setSubmitStatus({ type: "", message: "" });
      }, 5000); //5 seconds
    } catch (error) {
      console.error("Registration failed:", {
        code: error.code,
        message: error.message,
        data: error.customData,
        stack: error.stack,
      });

      setSubmitStatus({
        type: "error",
        message: error.message || "Registration failed. Please try again.",
      });

      setTimeout(() => {
        setSubmitStatus({ type: "", message: "" });
      }, 5000); //5 seconds
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSocialClick = (platform) => {
    trackSocialShare(platform.toLowerCase());
    trackEvent({
      category: "Social",
      action: "Click",
      label: platform,
    });
  };

  if (contentLoading || seoLoading) return null;

  return (
    <>
      <Helmet>
        {/* Primary Meta Tags */}
        <title>{seoSettings.metaTags.title}</title>
        <meta name="description" content={seoSettings.metaTags.description} />
        <meta name="theme-color" content={seoSettings.metaTags.themeColor} />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seoSettings.openGraph.url} />
        <meta property="og:title" content={seoSettings.openGraph.title} />
        <meta
          property="og:description"
          content={seoSettings.openGraph.description}
        />
        <meta property="og:image" content={seoSettings.openGraph.image} />

        {/* Twitter */}
        <meta property="twitter:card" content={seoSettings.twitter.card} />
        <meta property="twitter:url" content={seoSettings.openGraph.url} />
        <meta property="twitter:title" content={seoSettings.twitter.title} />
        <meta
          property="twitter:description"
          content={seoSettings.twitter.description}
        />
        <meta property="twitter:image" content={seoSettings.twitter.image} />

        {/* Google Analytics */}
        {seoSettings.googleAnalytics.id && (
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${seoSettings.googleAnalytics.id}');
            `}
          </script>
        )}
      </Helmet>
      <div className="relative min-h-screen w-full flex overflow-hidden">
        {/* Main content section */}
        <div
          className="relative w-full min-h-screen z-10"
          style={{
            background: `linear-gradient(to bottom right, ${colors.backgroundDark}, ${colors.backgroundLight})`,
          }}
        >
          {/* Content Container */}
          <div className="w-full py-12 flex flex-col min-h-screen justify-center max-w-5xl mx-auto px-8 items-center text-center">
            {/* Logo Area */}
            <motion.div
              initial={{ opacity: 0, y: -100 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1.6 }}
              className="mb-16 flex items-center justify-center"
            >
              <img
                src="/TD4-colour.png"
                alt="Tour De Four Logo"
                style={{
                  width: "100px",
                  height: "auto",
                }}
              />
            </motion.div>

            {/* Text Content */}
            <motion.div
              initial={{ opacity: 0, y: 40 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1.4, delay: 0.2 }}
              className="mb-16 flex flex-col items-center"
            >
              <h1 className="text-7xl md:text-7xl font-bold mb-6 text-white tracking-tight">
                {content.hero.title || "TOUR DE"}
                <span style={{ color: colors.accent }} className="mx-2">
                  4
                </span>
              </h1>

              <h2 className="text-lg md:text-2xl mb-8 text-white/80">
                <span style={{ color: colors.accent }}>
                  {content.hero.subtitle || "COMING SOON"}
                </span>
              </h2>

              <div className="text-lg md:text-xl text-white/80 space-y-8">
                <p>
                  {content.mainContent?.paragraph1 ||
                    " Following Sir Chris Hoy's diagnosis,<span style={{ color: colors.secondary }}>Tour de 4 </span> is abrand new fundraising charity bike ride that aims to shine a spotlight on what a Stage 4 cancer diagnosis can look like and demonstrate that it is possible to live well and lead a happy life alongside this devastating diagnosis. "}
                </p>

                <p>
                  {content.mainContent?.paragraph2 ||
                    "Chris' dream is to get as many people as possible living with Stage 4 cancer, as well as their friends and families, to participate with me to whatever extent they can manage, raising money for the cancer charities that mean the most to them."}{" "}
                </p>

                {/* Circular image */}
                <div className="w-full flex justify-center my-8">
                  <div
                    className="w-64 h-64 rounded-full overflow-hidden"
                    style={{
                      borderRadius: "50%",
                      boxShadow: `10px 5px 1px 2px ${colors.secondary}`,
                    }}
                  >
                    <img
                      src={content.mainContent?.imageSrc || "/Peligoni-10.webp"}
                      alt="Tour De Four"
                      className="w-full h-full object-cover"
                    />
                  </div>
                </div>

                <p className="italic">
                  <span style={{ color: colors.accent }}>"</span>
                  {content.mainContent?.quote ||
                    "I'm excited about the potential of this event and the work we can collectively do to help raise awareness of living with Stage 4 cancer and, crucially, the vital funds we will hopefully raise to help support the research and great work that is being done to tackle this global pandemic head on. I know there is not a cure for me but, with science moving so fast, I hope that we can protect our children and the next generation."}
                  <span style={{ color: colors.accent }}>"</span>
                </p>
              </div>
            </motion.div>

            {/* Registration Form */}
            <motion.form
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.4 }}
              onSubmit={handleSubmit}
              className="w-full max-w-5xl mb-12 mx-auto"
            >
              <div className="relative flex flex-col space-y-2">
                <div className="relative flex items-center bg-white/10 rounded-full backdrop-blur-sm p-2 shadow-xl">
                  <ResponsiveInput
                    value={email}
                    onChange={handleEmailChange}
                    error={emailError}
                  />
                  <AnimatePresence mode="wait">
                    <motion.button
                      type="submit"
                      disabled={isSubmitting}
                      style={{ backgroundColor: colors.secondary }}
                      className="ml-2 px-6 py-3 rounded-full text-white font-semibold transition-all hover:opacity-90 disabled:opacity-70"
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      {isSubmitting ? (
                        <motion.div
                          animate={{ rotate: 360 }}
                          transition={{
                            duration: 1,
                            repeat: Infinity,
                            ease: "linear",
                          }}
                        >
                          ⟳
                        </motion.div>
                      ) : (
                        <ChevronRight className="w-5 h-5" />
                      )}
                    </motion.button>
                  </AnimatePresence>
                </div>

                {/* Error/Status Messages */}
                <AnimatePresence>
                  {(emailError || submitStatus.message) && (
                    <motion.div
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0 }}
                      className={`text-sm ${
                        emailError
                          ? "text-red-400"
                          : submitStatus.type === "success"
                          ? "text-green-400"
                          : submitStatus.type === "info"
                          ? "text-blue-400"
                          : "text-red-400"
                      }`}
                    >
                      {emailError || submitStatus.message}
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </motion.form>

            {/* Social Links */}
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.8, delay: 0.6 }}
              className="flex space-x-8 justify-center"
            >
              {[
                { Icon: Facebook, label: "Facebook" },
                { Icon: Twitter, label: "Twitter" },
                { Icon: Instagram, label: "Instagram" },
              ].map(({ Icon, label }) => (
                <motion.a
                  key={label}
                  href="#"
                  onClick={() => handleSocialClick(label)}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                  className="text-white/80 hover:text-secondary transition-colors duration-300"
                >
                  <Icon className="w-6 h-6" />
                  <span className="sr-only">{label}</span>
                </motion.a>
              ))}
            </motion.div>
          </div>
        </div>

        {/* Background gradient */}
        <div
          className="absolute inset-0 -z-10"
          style={{
            background: `linear-gradient(to bottom right, ${colors.backgroundDark}, ${colors.backgroundLight})`,
          }}
        />
      </div>
    </>
  );
};

export default LandingPage;
