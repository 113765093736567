// src/Components/admin/ImageUploader.js
import React, { useState } from "react";
import { motion } from "framer-motion";
import { uploadImage } from "../../utils/imageUpload";

const ImageUploader = ({ currentImage, onImageUpload }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [previewUrl, setPreviewUrl] = useState(currentImage);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // Preview
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);

    // Upload
    setIsUploading(true);
    try {
      const imageUrl = await uploadImage(file);
      onImageUpload(imageUrl);
    } catch (error) {
      console.error("Upload failed:", error);
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center space-x-4">
        {/* Current/Preview Image */}
        <div className="relative w-32 h-32 border rounded-lg overflow-hidden">
          {previewUrl ? (
            <img
              src={previewUrl}
              alt="Preview"
              className="w-full h-full object-cover"
            />
          ) : (
            <div className="w-full h-full flex items-center justify-center bg-gray-100">
              No image
            </div>
          )}
        </div>

        {/* Upload Controls */}
        <div className="flex-1">
          <label className="block">
            <span className="sr-only">Choose image</span>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              disabled={isUploading}
              className="block w-full text-sm text-gray-500
                file:mr-4 file:py-2 file:px-4
                file:rounded-full file:border-0
                file:text-sm file:font-semibold
                file:bg-blue-50 file:text-blue-700
                hover:file:bg-blue-100"
            />
          </label>

          {isUploading && (
            <motion.div
              initial={{ width: 0 }}
              animate={{ width: `${uploadProgress}%` }}
              className="h-2 bg-blue-500 rounded-full mt-2"
            />
          )}
        </div>
      </div>

      {/* Image Requirements */}
      <div className="text-sm text-gray-500">
        <p>Recommended: Square image, minimum 500x500px</p>
        <p>Maximum size: 5MB</p>
      </div>
    </div>
  );
};

export default ImageUploader;
