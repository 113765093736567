import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import CookieConsentBanner from "./Components/CookieConsentBanner";

// Create root
const container = document.getElementById("root");
const root = createRoot(container);

// Render app
root.render(
  <React.StrictMode>
    <App />
    <CookieConsentBanner />
  </React.StrictMode>
);

// Register service worker
// In your index.js or wherever you register the service worker
serviceWorkerRegistration.register({
  onSuccess: (registration) => {
    console.log("Service Worker registration successful");
  },
  onUpdate: (registration) => {
    console.log("New content is available; please refresh.");
  },
  onError: (error) => {
    console.error("Error during service worker registration:", error);
  },
});
