import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  getAnalytics,
  setAnalyticsCollectionEnabled,
} from "firebase/analytics";
import { app } from "../firebase"; // Make sure to export app from firebase.js

const COOKIE_CONSENT_KEY = "cookieConsent";
const ANALYTICS_CONSENT_KEY = "analyticsConsent";

const useCookieConsent = () => {
  const [hasConsent, setHasConsent] = useState(() => {
    return localStorage.getItem(COOKIE_CONSENT_KEY) === "true";
  });

  const [analyticsEnabled, setAnalyticsEnabled] = useState(() => {
    return localStorage.getItem(ANALYTICS_CONSENT_KEY) === "true";
  });

  const [analyticsInstance, setAnalyticsInstance] = useState(null);

  useEffect(() => {
    // Initialize analytics if consent was previously given
    if (localStorage.getItem(ANALYTICS_CONSENT_KEY) === "true") {
      try {
        const analytics = getAnalytics(app);
        setAnalyticsInstance(analytics);
        setAnalyticsCollectionEnabled(analytics, true);
      } catch (error) {
        console.error("Error initializing analytics:", error);
      }
    }
  }, []);

  const setConsent = (value) => {
    setHasConsent(value);
    localStorage.setItem(COOKIE_CONSENT_KEY, value);
  };

  const setAnalytics = async (value) => {
    try {
      let analytics = analyticsInstance;

      if (value && !analytics) {
        // Initialize analytics if it hasn't been initialized yet
        analytics = getAnalytics(app);
        setAnalyticsInstance(analytics);
      }

      if (analytics) {
        await setAnalyticsCollectionEnabled(analytics, value);
        setAnalyticsEnabled(value);
        localStorage.setItem(ANALYTICS_CONSENT_KEY, value);
      }
    } catch (error) {
      console.error("Error setting analytics:", error);
    }
  };

  return { hasConsent, setConsent, analyticsEnabled, setAnalytics };
};

const CookieConsentBanner = () => {
  const [showSettings, setShowSettings] = useState(false);
  const { hasConsent, setConsent, analyticsEnabled, setAnalytics } =
    useCookieConsent();

  const colors = {
    primaryDark: "#304b78",
    primaryLight: "#435e95",
    secondary: "#2494a2",
    accent: "#f3d678",
    backgroundDark: "#0f172a",
    backgroundLight: "#1e293b",
  };

  const handleAccept = () => {
    setConsent(true);
    setAnalytics(true);
  };

  const handleDecline = () => {
    setConsent(true);
    setAnalytics(false);
  };

  const toggleAnalytics = () => {
    setAnalytics(!analyticsEnabled);
  };

  if (hasConsent) {
    return null;
  }

  return (
    <>
      <div
        style={{
          background: colors.backgroundDark,
          borderTop: `1px solid ${colors.primaryLight}`,
        }}
        className="fixed bottom-0 left-0 right-0 px-4 py-4 sm:px-6"
      >
        <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4">
          <div className="flex flex-col gap-2">
            <h3 className="text-lg font-semibold text-white">
              We value your privacy
            </h3>
            <p className="text-sm text-gray-300">
              We use cookies to enhance your browsing experience, serve
              personalized content, and analyze our traffic. We also share
              information about your use of our site with our analytics
              partners.
            </p>
          </div>
          <div className="flex gap-3">
            <button
              onClick={() => setShowSettings(true)}
              className="text-sm text-accent text-white underline hover:text-accent/80 transition-colors"
            >
              Cookie Settings
            </button>
            <button
              onClick={handleDecline}
              style={{
                borderColor: colors.accent,
                color: colors.accent,
              }}
              className="px-4 py-2 border rounded hover:bg-accent/10 transition-colors"
            >
              Decline
            </button>
            <button
              onClick={handleAccept}
              style={{
                backgroundColor: colors.accent,
                color: colors.backgroundDark,
              }}
              className="px-4 py-2 rounded hover:opacity-90 transition-opacity"
            >
              Accept All
            </button>
          </div>
        </div>
      </div>

      <AnimatePresence>
        {showSettings && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/50 flex items-end sm:items-center justify-center z-50"
            onClick={(e) => {
              if (e.target === e.currentTarget) setShowSettings(false);
            }}
          >
            <motion.div
              initial={{ y: "100%" }}
              animate={{ y: 0 }}
              exit={{ y: "100%" }}
              transition={{ type: "spring", damping: 25 }}
              style={{
                background: colors.backgroundDark,
              }}
              className="w-full max-w-lg rounded-t-lg sm:rounded-lg p-6 space-y-4 shadow-xl"
            >
              <div className="flex justify-between items-center">
                <h3 className="text-xl font-bold text-white">
                  Cookie Settings
                </h3>
                <button
                  onClick={() => setShowSettings(false)}
                  className="text-gray-300 hover:text-white transition-colors"
                >
                  ✕
                </button>
              </div>

              <div className="space-y-4">
                <div
                  style={{ background: colors.backgroundLight }}
                  className="flex items-center justify-between p-4 rounded-lg"
                >
                  <div>
                    <h4 className="text-white font-medium">
                      Essential Cookies
                    </h4>
                    <p className="text-sm text-gray-200">
                      Required for the website to function properly
                    </p>
                  </div>
                  <div className="w-12 h-6 bg-gray-500/50 rounded-full relative cursor-not-allowed">
                    <div className="absolute right-1 top-1 w-4 h-4 bg-gray-300 rounded-full" />
                  </div>
                </div>

                <div
                  style={{ background: colors.backgroundLight }}
                  className="flex items-center justify-between p-4 rounded-lg"
                >
                  <div>
                    <h4 className="text-white font-medium">
                      Analytics Cookies
                    </h4>
                    <p className="text-sm text-gray-200">
                      Help us understand how visitors interact with our website
                    </p>
                  </div>
                  <button
                    onClick={toggleAnalytics}
                    style={{
                      backgroundColor: analyticsEnabled
                        ? colors.accent
                        : "#4B5563",
                    }}
                    className="w-12 h-6 rounded-full relative transition-colors"
                  >
                    <motion.div
                      className="absolute top-1 w-4 h-4 bg-white rounded-full"
                      animate={{ x: analyticsEnabled ? 28 : 4 }}
                      transition={{
                        type: "spring",
                        stiffness: 500,
                        damping: 30,
                      }}
                      whileHover={{ scale: 1.1 }}
                    />
                  </button>
                </div>
              </div>

              <div className="flex gap-3 justify-end">
                <button
                  onClick={() => setShowSettings(false)}
                  style={{
                    borderColor: colors.accent,
                    color: colors.accent,
                  }}
                  className="px-4 py-2 border-2 rounded hover:bg-accent/10 transition-colors font-medium"
                >
                  Cancel
                </button>
                <button
                  onClick={() => setShowSettings(false)}
                  style={{
                    backgroundColor: colors.accent,
                    color: colors.primaryDark,
                  }}
                  className="px-4 py-2 rounded hover:opacity-90 transition-opacity font-medium"
                >
                  Save Preferences
                </button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default CookieConsentBanner;
