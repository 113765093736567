import { analytics } from "../firebase";
import { logEvent } from "firebase/analytics";

export const trackPageView = (page, title) => {
  logEvent(analytics, "page_view", {
    page_path: page,
    page_title: title,
  });
};

export const trackEvent = ({ category, action, label }) => {
  logEvent(analytics, action, {
    event_category: category,
    event_label: label,
  });
};

export const trackEmailSignup = (source) => {
  logEvent(analytics, "sign_up", {
    method: "email",
    source: source,
  });
};

export const trackSocialShare = (platform) => {
  logEvent(analytics, "share", {
    method: platform,
  });
};
