import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";

const ProtectedRoute = ({ children, routeName = "mainLandingPage" }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [routeProtection, setRouteProtection] = useState(true);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  // Fetch route protection settings
  useEffect(() => {
    const fetchRouteProtection = async () => {
      try {
        const docRef = doc(db, "settings", "routeProtection");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const settings = docSnap.data();
          // If it's the admin portal, always require protection
          setRouteProtection(
            routeName === "adminPortal" ? true : settings[routeName] ?? true
          );
        }
      } catch (error) {
        console.error("Error fetching route protection:", error);
        // Default to protected if there's an error
        setRouteProtection(true);
      } finally {
        setLoading(false);
      }
    };

    fetchRouteProtection();
  }, [routeName]);

  // Check authentication status
  useEffect(() => {
    const authStatus = sessionStorage.getItem("isAuthenticated");
    setIsAuthenticated(authStatus === "true");
  }, [location.pathname]);

  const handleLogin = (e) => {
    e.preventDefault();
    const isValid =
      username === process.env.REACT_APP_AUTH_USERNAME &&
      password === process.env.REACT_APP_AUTH_PASSWORD;

    if (isValid) {
      sessionStorage.setItem("isAuthenticated", "true");
      setIsAuthenticated(true);
      setError("");
    } else {
      setError("Invalid credentials");
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen w-full flex items-center justify-center">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-white"></div>
      </div>
    );
  }

  // If route doesn't need protection or user is already authenticated, render children
  if (!routeProtection || isAuthenticated) {
    return children;
  }

  // Show login form if protection is required and user isn't authenticated
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="min-h-screen w-full flex items-center justify-center"
      style={{
        background: `linear-gradient(to bottom right, #0f172a, #1e293b)`,
      }}
    >
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="w-full max-w-md p-6"
      >
        <form
          onSubmit={handleLogin}
          className="bg-white/10 backdrop-blur-sm rounded-lg p-8 shadow-xl"
        >
          <h2 className="text-xl text-white mb-8 font-semibold">
            Enter Site Credentials
          </h2>
          {error && (
            <div className="mb-4 text-red-400 text-sm text-center">{error}</div>
          )}
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Username"
            className="w-full px-4 py-2 rounded-md mb-4 bg-white/5 border border-white/10 text-white"
            required
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            className="w-full px-4 py-2 rounded-md mb-4 bg-white/5 border border-white/10 text-white"
            required
          />
          <motion.button
            type="submit"
            className="w-full px-4 py-2 bg-[#2494a2] text-white rounded-md"
            whileHover={{ opacity: 0.9 }}
            whileTap={{ scale: 0.98 }}
          >
            Login
          </motion.button>
        </form>
      </motion.div>
    </motion.div>
  );
};

export default ProtectedRoute;
