// src/Components/admin/components/SocialCardPreview.js
import React from "react";
import { motion } from "framer-motion";

const SocialCardPreview = ({ platform, data }) => {
  const platformStyles = {
    facebook: {
      wrapper: "max-w-[524px] font-sans",
      image: "h-[273px]",
      titleSize: "text-[16px] leading-[20px] font-semibold",
      descSize: "text-[14px] leading-[20px]",
      urlSize: "text-[12px] leading-[11px]",
      bg: "bg-[#f2f3f5]",
      textColor: "text-[#1d2129]",
      urlColor: "text-[#606770]",
    },
    twitter: {
      wrapper: "max-w-[504px] font-sans",
      image: "h-[252px]",
      titleSize: "text-[15px] leading-[20px] font-bold",
      descSize: "text-[15px] leading-[20px]",
      urlSize: "text-[15px] leading-[20px]",
      bg: "bg-white",
      textColor: "text-[#000000]",
      urlColor: "text-[#536471]",
    },
  };

  const style = platformStyles[platform];

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className={`${style.wrapper} rounded-2xl overflow-hidden border shadow-lg`}
    >
      {/* Image */}
      <div className={`${style.bg} w-full ${style.image} relative`}>
        <img
          src={data.image || "/TD4-colour.png"}
          alt="Social preview"
          className="w-full h-full object-cover"
        />
      </div>

      {/* Content */}
      <div className={`${style.bg} p-3 space-y-1`}>
        {/* URL */}
        <div className={`${style.urlSize} ${style.urlColor} uppercase`}>
          {data.url?.replace("https://", "").split("/")[0] || "tourde4.com"}
        </div>

        {/* Title */}
        <div className={`${style.titleSize} ${style.textColor}`}>
          {data.title}
        </div>

        {/* Description */}
        <div
          className={`${style.descSize} ${style.textColor} opacity-90 line-clamp-2`}
        >
          {data.description}
        </div>
      </div>
    </motion.div>
  );
};

export default SocialCardPreview;
