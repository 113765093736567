// src/hooks/useContent.js
import { useState, useEffect } from "react";
import { db } from "../firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";

export const useContent = () => {
  const [content, setContent] = useState({
    hero: {
      title: "",
      subtitle: "",
    },
    mainContent: {
      imageSrc: "/Peligoni-10.webp",
      paragraph1: "",
      paragraph2: "",
      quote: "",
    },
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const loadContent = async () => {
    try {
      const docRef = doc(db, "content", "landingPage");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setContent(docSnap.data());
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadContent();
  }, []);

  const saveContent = async (newContent) => {
    try {
      await setDoc(doc(db, "content", "landingPage"), newContent);
      setContent(newContent);
      return true;
    } catch (err) {
      setError(err.message);
      return false;
    }
  };

  return { content, setContent, saveContent, loading, error };
};
