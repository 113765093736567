// src/hooks/useSeoSettings.js
import { useState, useEffect } from "react";
import { db } from "../firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";

export const useSeoSettings = () => {
  const [seoSettings, setSeoSettings] = useState({
    metaTags: {
      title: "Tour De 4 - A Fundraising Cycle Event with Sir Chris Hoy",
      description:
        "Join Sir Chris Hoy's fundraising charity bike ride aimed at shining a spotlight on Stage 4 cancer diagnosis and demonstrating that it's possible to live well alongside this devastating diagnosis.",
      themeColor: "#0f172a",
    },
    openGraph: {
      title: "Tour De 4 - A Fundraising Cycle Event with Sir Chris Hoy",
      description:
        "Join Sir Chris Hoy's fundraising charity bike ride aimed at shining a spotlight on Stage 4 cancer diagnosis and demonstrating that it's possible to live well alongside this devastating diagnosis.",
      url: "https://tourde4.com/",
      image: "/TD4-colour.png",
    },
    twitter: {
      title: "Tour De 4 - A Fundraising Cycle Event with Sir Chris Hoy",
      description:
        "Join Sir Chris Hoy's fundraising charity bike ride aimed at shining a spotlight on Stage 4 cancer diagnosis and demonstrating that it's possible to live well alongside this devastating diagnosis.",
      image: "/TD4-colour.png",
      card: "summary_large_image",
    },
    googleAnalytics: {
      id: "G-7KERDK7LB0",
      tagManagerId: "GT-5GK56F4K",
    },
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const loadSeoSettings = async () => {
    try {
      const docRef = doc(db, "settings", "seo");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setSeoSettings(docSnap.data());
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadSeoSettings();
  }, []);

  const saveSeoSettings = async (newSettings) => {
    try {
      await setDoc(doc(db, "settings", "seo"), newSettings);
      setSeoSettings(newSettings);
      return true;
    } catch (err) {
      setError(err.message);
      return false;
    }
  };

  return { seoSettings, setSeoSettings, saveSeoSettings, loading, error };
};
